<template>
  <b-nav-item-dropdown
    link-classes="bookmark-star"
    lazy
    id="dropdown-grouped"
    variant="link"
    right
  >
    <template #button-content>
      <IconDatabases
        v-b-tooltip.hover
        :title="$t('MySites.Title')"
        class="icon-database"
      />
      <IconArrowDown class="icon-arrow-down" />
    </template>
    <b-dropdown-item
      v-for="site in sites"
      :key="site.guid"
      @click="setSite(site)"
      :style="getItemColor(site, 'item')"
      class="item-site"
    >
      <feather-icon
        v-b-tooltip.hover
        size="21"
        title="Sites"
        icon="DatabaseIcon"
        :style="getItemColor(site, 'icon')"
        class="icon-site"
      />
      <span :style="getItemColor(site, 'name')">{{ site.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
  import { BNavItem, VBTooltip, BNavItemDropdown, BDropdownItem, BRow } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import IconArrowDown from '@/assets/images/pages/arrow-down-icon.svg';
  import IconDatabases from '@/assets/images/pages/database-icon.svg';

  export default {
    components: {
      BRow,
      BNavItem,
      VBTooltip,
      BNavItemDropdown,
      BDropdownItem,
      ToastificationContent,
      IconArrowDown,
      IconDatabases
    },
    directives: {
      'b-tooltip': VBTooltip,
      Ripple
    },
    data() {
      return {
        prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
        sites: null
      };
    },
    created() {
      this.getSites();
    },
    methods: {
      getSites() {
        this.sites = JSON.parse(localStorage.getItem('sites'));
      },
      setSite(site) {
        localStorage.setItem('currentSite', JSON.stringify(site));
        this.$router.go();
      },
      getItemColor(site, type) {
        let currentSite = JSON.parse(localStorage.getItem('currentSite'));
        if (currentSite && currentSite.guid == site.guid) {
          if (type == 'item') {
            return 'background-color: #974900; color: #ffff !important';
          } else {
            return 'color: #ffff !important';
          }
        }
        return 'background-color: transparent ; color: #974900';
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      }
    }
  };
</script>
<style lang="scss">
  .item-site {
    .dropdown-item:active {
      background-color: #ffdbc4 !important;
      svg {
        color: #974900;
      }
      span {
        color: #974900 !important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';

  .item-site {
    .icon-site {
      margin-right: 10px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  p {
    margin: 0;
  }
</style>

<style lang="scss">
  .icon-arrow-down {
    margin-left: 8px !important;
    margin-top: 0 !important;
  }
</style>
