<template>
  <b-nav-item @click="skin = isDark ? 'light' : 'dark'">
    <feather-icon
      v-b-tooltip.hover
      size="21"
      :title="isDark ? $t('Light Theme') : $t('Dark Theme')"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
</template>

<script>
  import useAppConfig from '@core/app-config/useAppConfig';
  import { computed } from '@vue/composition-api';
  import { BNavItem, VBTooltip } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';

  export default {
    components: {
      BNavItem,
      VBTooltip
    },
    directives: {
      'b-tooltip': VBTooltip,
      Ripple
    },
    setup() {
      const { skin } = useAppConfig();

      const isDark = computed(() => skin.value === 'dark');

      return { skin, isDark };
    }
  };
</script>
