<template>
  <div v-if="variant == 'short'">
    <NavItem
      :id="'settings-target-' + name"
      :name="name"
      :selected="checkSelected()"
      :border="false"
      :variant="variant"
      :icon="icon"
      tabindex="0"
    >
    </NavItem>
    <CollapsePopover
      :title="$t(name)"
      :items="items"
      :target="'settings-target-' + name"
      placement="rightbottom"
    />
  </div>

  <div
    v-else
    :class="['settings-list', open ? 'collapse-sidebar-open' : '']"
  >
    <div
      :class="['nav-item-full', selectedUnit ? 'unit-dropdown' : '']"
      @click="open = !open"
    >
      <div class="icon-name-block">
        <div
          v-show="icon"
          :class="icon ? 'icon-full' : ''"
        >
          <component :is="icon" />
        </div>
        <div class="name-block">
          <span>{{ name }}</span>
          <span
            class="unit-selected"
            v-show="selectedUnit"
          >
            {{ selectedUnit }}
          </span>
        </div>
      </div>
      <div :class="['icon-dropdown', open ? 'flipped' : '']">
        <ArrowRight />
      </div>
    </div>
    <transition name="navgroup">
      <div
        v-if="open"
        :class="[
          'settings-items',
          selectedUnit ? 'units-options' : '',
          ident ? 'child-settings' : ''
        ]"
      >
        <div
          v-for="item in items"
          :key="item.title"
        >
          <CollapseNavItemComponent
            v-if="item.items"
            :name="$t(item.title)"
            :items="item.items"
            :variant="variant"
            :ident="true"
          />
          <NavItem
            v-else
            :id="'settings-target-' + name"
            :key="'key-' + item.title"
            :name="$t(item.title)"
            :selected="currentPage.includes(item.slug)"
            :border="false"
            :variant="variant"
            @click="item.click()"
            :ident="ident"
          >
          </NavItem>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import NavItem from './NavItem.vue';
import NavItemGroup from './NavItemGroup.vue';
import CollapsePopover from './CollapsePopover.vue';
import Tooltip from './Tooltip.vue';

import ArrowRight from '@core/assets/icons/arrow-right.svg';

export default {
  name: 'CollapseNavItemComponent',
  props: {
    variant: { type: String, default: 'short' },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    name: {
      type: String,
      required: true
    },
    selectedUnit: {
      type: String
    },
    icon: {
      type: Object
    },
    ident: {
      type: Boolean,
      default: false
    },
    closeDropdown: {
      type: Boolean
    }
  },
  components: {
    NavItem,
    NavItemGroup,
    CollapsePopover,
    Tooltip,
    ArrowRight
  },
  methods: {
    checkSelected() {
      let flag = false;
      this.items.forEach((item) => {
        if (this.currentPage.includes(item.slug)) flag = true;
        if (item.items) {
          item.items.forEach((subitem) => {
            if (this.currentPage.includes(subitem.slug)) flag = true;
          });
        }
      });
      return flag;
    }
  },
  computed: {
    currentPage() {
      return this.$route.path;
    }
  },
  watch: {
    closeDropdown(v) {
      v === false && (this.open = false);
    }
  },
  data() {
    return {
      open: false
    };
  }
};
</script>
<style lang="scss" scoped>
.flipped {
  transform: rotate(90deg);
  transform-origin: center;
}
.icon-full {
  svg {
    margin: 0 0 3px;
    width: 14px;
    height: 14px;
    fill: #998f8a;
  }
}

.settings-items {
  &.units-options {
    .nav-item-full {
      padding-left: 16px;
    }
  }
  .nav-item-full {
    padding-left: 36px;
  }
  &.child-settings {
    .nav-item-full {
      padding-left: 56px;
    }
  }
  &.units-options {
    padding-left: 0;
  }
}
.icon-dropdown {
  width: 16px;
  height: 100%;

  svg {
    fill: #998f8a;
  }
}

.nav-item-full {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 16px;
  gap: 8px;
  line-height: 20px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  justify-content: space-between;
  align-items: center;
  
  cursor: pointer;
  user-select: none;

  .icon-name-block {
    display: flex;
    gap: 8px;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex: 1;
    color: #998f8a;

    font-style: normal;
    font-weight: 400;
  }

  &.unit-dropdown {
    display: flex;
    span {
      &:first-child {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: #4c4541;
      }
    }
  }

  &:hover {
    background-color: #ffede2;
    svg {
      fill: #974900;
    }
    span {
      color: #974900;
    }
  }

  &:active {
    background-color: #ffdbc4;
    color: #974900;
  }

  &.selected {
    background-color: #974900;
    svg {
      fill: #fff !important;
    }
    span {
      color: #fff;
    }
  }
}
</style>
