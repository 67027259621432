<template>
  <div v-if="variant == 'short'">
    <NavItem
      id="extra"
      :name="$t('Menu.Groups.Extra')"
      :border="false"
      :variant="variant"
      :icon="icons.ExtraIcon"
      :hiddenTooltip="hiddenTooltip"
      tabindex="0"
    >
    </NavItem>

    <ExtraPopover :title="title" :items="items" target="extra" placement="bottom" />
  </div>
</template>

<script>
  import NavItem from '../NavItem.vue';
  import NavItemGroup from '../NavItemGroup.vue';
  import ExtraPopover from './ExtraPopover.vue';

  import ExtraIcon from '@core/assets/icons/extra.svg';

  const ICONS = {
    ExtraIcon
  };

  export default {
    name: 'ExtraComponent',
    props: {
      items: {
        type: Array,
        default() {
          return [];
        }
      },
      hiddenTooltip: { type: Boolean, default: false},
      title: { type: String },
      variant: { type: String, default: 'short' }
    },
    components: {
      ExtraIcon,
      NavItem,
      NavItemGroup,
      ExtraPopover
    },
    data() {
      return {
        icons: ICONS
      };
    }
  };
</script>
