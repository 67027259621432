export default [
  {
    title: 'Orders',
    icon: 'CalendarIcon',
    route: 'order-list',
    action: 'read',
    resource: 'pcp'
  },
  {
    title: 'Production Progress',
    icon: 'TrendingUpIcon',
    route: 'production-progress',
    action: 'read',
    resource: 'pcp'
  },
  {
    header: 'Dashboards',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'Resource Loading',
        route: 'resource-loading',
        icon: 'BarChart2Icon',
        action: 'read',
        resource: 'pcp'
      },
      {
        title: 'Schedule Analysis',
        route: 'schedule-analysis',
        icon: 'PieChartIcon',
        action: 'read',
        resource: 'pcp'
      },
      {
        title: 'OEE',
        route: 'oee',
        icon: 'TrendingUpIcon',
        action: 'read',
        resource: 'pcp'
      }
    ]
  },
  {
    header: 'System Setting',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'WorkCenters',
        route: 'workcenters',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'pcp'
      },
      {
        title: 'Classification',
        icon: 'ListIcon',
        action: 'read',
        resource: 'pcp',
        children: [
          {
            title: 'Refuse Status',
            route: 'refuse-status',
            icon: 'ChevronRightIcon',
            action: 'read',
            resource: 'pcp'
          },
          {
            title: 'Resource Status',
            route: 'resource-status',
            icon: 'ChevronRightIcon',
            action: 'read',
            resource: 'pcp'
          }
        ]
      },
      {
        title: 'Resources',
        route: 'resources',
        icon: 'SlidersIcon',
        action: 'read',
        resource: 'pcp'
      },
      {
        title: 'Sites',
        route: 'sites',
        icon: 'DatabaseIcon',
        action: 'read',
        resource: 'pcp'
      }
    ]
  },
  {
    header: 'Integration',
    icon: 'Link2Icon',
    children: [
      {
        title: 'Data Source',
        route: 'data-source',
        icon: 'ExternalLinkIcon',
        action: 'read',
        resource: 'integration'
      },
      {
        title: 'Pipelines',
        route: 'pipelines',
        icon: 'SettingsIcon',
        action: 'read',
        resource: 'integration'
      },
      {
        title: 'Log Center',
        route: 'log-center',
        icon: 'LogInIcon',
        action: 'read',
        resource: 'integration'
      },
      {
        title: 'Download',
        route: 'download-client',
        icon: 'DownloadIcon',
        action: 'read',
        resource: 'integration'
      },
      {
        title: 'secondaryPage',
        route: 'new-pipeline',
        icon: '',
        action: 'read',
        resource: 'integration'
      },
      {
        title: 'secondaryPage',
        route: 'object',
        icon: '',
        action: 'read',
        resource: 'integration'
      }
    ]
  }
];
