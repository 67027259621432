<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderLink(item)"
    class="nav-item"
    :class="{ 'sidebar-group-active active': isActive }"
  >
    <b-link
      class="nav-link"
      :to="{ name: item.route }"
    >
      <IconOperationProgress v-if="item.title == 'Production Progress'" />
      <IconOrder v-else-if="item.title == 'Orders'" />

      <span>{{ t(item.title) }}</span>
    </b-link>
  </li>
</template>

<script>
  import { BLink } from 'bootstrap-vue';
  import { useUtils as useI18nUtils } from '@core/libs/i18n';
  import { useUtils as useAclUtils } from '@core/libs/acl';
  import useHorizontalNavMenuHeaderLink from './useHorizontalNavMenuHeaderLink';
  import mixinHorizontalNavMenuHeaderLink from './mixinHorizontalNavMenuHeaderLink';
  import IconOperationProgress from '@/assets/images/pages/operation-progress-page-icon.svg';
  import IconOrder from '@/assets/images/pages/order-page-icon.svg';

  export default {
    components: {
      BLink,
      IconOperationProgress,
      IconOrder
    },
    mixins: [mixinHorizontalNavMenuHeaderLink],
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(props.item);

      const { t } = useI18nUtils();
      const { canViewHorizontalNavMenuHeaderLink } = useAclUtils();

      return {
        isActive,
        updateIsActive,

        // ACL
        canViewHorizontalNavMenuHeaderLink,

        // i18n
        t
      };
    }
  };
</script>
