import { $themeBreakpoints } from '@themeConfig';

export default {
  watch: {
    $route() {
      if (this.$store.state.app.windowWidth < 1253) {
        this.isVerticalMenuActive = false;
      }
    }
  }
};
