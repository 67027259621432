<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'light' || skin === 'bordered' ? 'menu-light' : 'menu-dark'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo-menu-vertical">
                <LogoVertical />
              </span>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <IconClose @click="toggleVerticalMenuActive" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
  import navMenuItems from '@/navigation/vertical';
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';
  import { BLink, BImg } from 'bootstrap-vue';
  import { provide, computed, ref } from '@vue/composition-api';
  import useAppConfig from '@core/app-config/useAppConfig';
  import { $themeConfig } from '@themeConfig';
  import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
  import useVerticalNavMenu from './useVerticalNavMenu';
  import LogoVertical from '@/assets/images/logo/logo-navbar-vertical.svg';
  import IconClose from '@/assets/images/pages/close-icon.svg';

  export default {
    components: {
      VuePerfectScrollbar,
      VerticalNavMenuItems,
      BLink,
      BImg,
      LogoVertical,
      IconClose
    },
    props: {
      isVerticalMenuActive: {
        type: Boolean,
        required: true
      },
      toggleVerticalMenuActive: {
        type: Function,
        required: true
      }
    },
    setup(props) {
      const {
        isMouseHovered,
        isVerticalMenuCollapsed,
        collapseTogglerIcon,
        toggleCollapsed,
        updateMouseHovered
      } = useVerticalNavMenu(props);

      const { skin } = useAppConfig();

      // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
      const shallShadowBottom = ref(false);

      provide('isMouseHovered', isMouseHovered);

      const perfectScrollbarSettings = {
        maxScrollbarLength: 60,
        wheelPropagation: false
      };

      const collapseTogglerIconFeather = computed(() =>
        collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'
      );

      // App Name
      const { appName, appLogoImage } = $themeConfig.app;

      return {
        navMenuItems,
        perfectScrollbarSettings,
        isVerticalMenuCollapsed,
        collapseTogglerIcon,
        toggleCollapsed,
        isMouseHovered,
        updateMouseHovered,
        collapseTogglerIconFeather,

        // Shadow Bottom
        shallShadowBottom,

        // Skin
        skin,

        // App Name
        appName,
        appLogoImage
      };
    }
  };
</script>

<style lang="scss">
  @import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
  @media (max-width: 480px) {
    .menu-open {
      .main-menu {
        width: 100%;
        transform: none;
        .navbar-header {
          width: 100%;
        }
      }
    }
  }

  .main-menu {
    .nav {
      display: flex;
      align-items: center;
      .navbar-brand {
        margin: 0 !important;
      }
      .nav-toggle {
        svg {
          path {
            fill: #974900;
          }
        }
      }
    }
    .navigation {
      display: flex;
      flex-direction: column;
      .nav-item {
        margin-bottom: 4px !important;
        &.mode {
          a {
            svg {
              stroke: #998f8a;
              path {
                fill: none;
              }
            }
          }
        }

        a {
          border-radius: 4px;
          background: transparent !important;
          box-shadow: none !important;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998f8a;
          }
          svg {
            path {
              fill: #998f8a;
            }
          }
          &::after {
            display: none;
          }
        }
        &:hover {
          &.has-sub {
            a {
              background: #ffdbc4 !important;
              svg {
                path {
                  fill: #974900 !important;
                }
              }
              span {
                color: #974900;
              }
            }
            .item-site,
            .item-locale {
              a {
                background-color: #ffff !important;
                span {
                  color: #998f8a;
                }
              }
            }
            .menu-content {
              .nav-item {
                a {
                  background: #ffff !important;
                  svg {
                    path {
                      fill: #998f8a;
                    }
                  }
                  span {
                    color: #998f8a;
                  }
                }
              }
            }
          }
        }
        &.active {
          a {
            background: #974900 !important;
            box-shadow: none !important;
            svg {
              path {
                fill: #ffff;
              }
            }
            span {
              color: #ffff;
            }
          }
        }
        .menu-content {
          .nav-item {
            a {
              padding-left: 48px !important;
            }

            &.active {
              box-shadow: none !important;
            }
          }
        }
      }
    }
  }
</style>
