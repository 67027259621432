<template>
  <button
    :id="id"
    :class="['profile-short', !url ? 'placeholder' : '']"
    tabindex="0"
  >
    <img
      v-if="url"
      :src="url"
      alt="Profile Image"
    />
    <span v-else>{{ placeholder }}</span>
  </button>
</template>

<script>
  export default {
    name: 'ProfilePicture',
    props: {
      id: {
        type: String
      },
      url: {
        type: String,
        default: null
      },
      placeholder: {
        type: String,
        default: 'LN'
      }
    }
  };
</script>

<style lang="scss" scoped>
  .profile-short {
    outline: 0;
    background-color: transparent;
    border: 0;

    width: 56px;
    height: 56px;
    padding: 8px;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    img {
      min-height: 44px;
      min-width: 44px;
      width: 44px;
      height: 44px;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  span {
    background-color: #ffdbc490;
    color: #512400;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }
</style>
