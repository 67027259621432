<template>
  <div
    class="avatar-placeholder"
    :style="{ width: `${size} !important`, height: `${size} !important` }"
  >
    {{ text }}
  </div>
</template>

<script>
  export default {
    props: {
      size: {
        type: String,
        default: '42px'
      },
      text: {
        type: String,
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  .avatar-placeholder {
    border-radius: 100%;

    background-color: #ffdbc490;
    color: #512400;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }
</style>
