<template>
  <li
    v-if="canViewHorizontalNavMenuLink(item)"
    :class="{
      active: isActive,
      disabled: item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      :class="`dropdown-item ${!isActive && item.title == 'Notifications' ? 'not-active' : ''}`"
    >
      <IconDowload v-if="item.title == 'Download'" />
      <IconCentralLog v-else-if="item.title == 'Log Center'" />
      <IconFonteDados v-else-if="item.title == 'Data Source'" />
      <IconPeople v-else-if="item.title == 'WorkCenters'" />
      <IconRecursosPrimarios v-else-if="item.title == 'Resources'" />
      <IconDatabase v-else-if="item.title == 'Sites'" />
      <IconSettings v-else-if="item.title == 'Pipelines'" />
      <IconOee
        v-else-if="item.title == 'OEE'"
        class="ooe-icon"
      />
      <IconOcupacaoRecursos v-else-if="item.title == 'Resource Loading'" />
      <IconCapacidadeUtilizada v-else-if="item.title == 'Schedule Analysis'" />
      <span class="menu-title">{{ t(item.title) }}</span>
    </b-link>
  </li>
</template>

<script>
  import { BLink } from 'bootstrap-vue';
  import { useUtils as useI18nUtils } from '@core/libs/i18n';
  import { useUtils as useAclUtils } from '@core/libs/acl';
  import useHorizontalNavMenuLink from './useHorizontalNavMenuLink';
  import mixinHorizontalNavMenuLink from './mixinHorizontalNavMenuLink';
  import IconDowload from '@/assets/images/pages/download-icon.svg';
  import IconNotificacao from '@/assets/images/pages/notificacao-icon.svg';
  import IconCentralLog from '@/assets/images/pages/central-log-icon.svg';
  import IconFonteDados from '@/assets/images/pages/fonte-dados-icon.svg';
  import IconPeople from '@/assets/images/pages/people-icon.svg';
  import IconRecursosPrimarios from '@/assets/images/pages/recursos-primarios-icon.svg';
  import IconDatabase from '@/assets/images/pages/database-navbar-icon.svg';
  import IconSettings from '@/assets/images/pages/settings-page-icon.svg';
  import IconOee from '@/assets/images/pages/oee-icon.svg';
  import IconOcupacaoRecursos from '@/assets/images/pages/ocupacao-recursos-icon.svg';
  import IconCapacidadeUtilizada from '@/assets/images/pages/capacidade-utilizada-icon.svg';

  export default {
    components: {
      BLink,
      IconDowload,
      IconNotificacao,
      IconCentralLog,
      IconFonteDados,
      IconPeople,
      IconRecursosPrimarios,
      IconDatabase,
      IconSettings,
      IconOee,
      IconOcupacaoRecursos,
      IconCapacidadeUtilizada
    },
    mixins: [mixinHorizontalNavMenuLink],
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const { isActive, linkProps, updateIsActive } = useHorizontalNavMenuLink(props.item);

      const { t } = useI18nUtils();
      const { canViewHorizontalNavMenuLink } = useAclUtils();

      return {
        isActive,
        linkProps,
        updateIsActive,

        // ACL
        canViewHorizontalNavMenuLink,

        // i18n
        t
      };
    }
  };
</script>

<style lang="scss">
  .navbar
    .navbar-container
    .bookmark-wrapper
    .header-navbar.navbar-horizontal
    .navbar-container
    .nav-item.active
    .dropdown-menu
    .dropdown-item.router-link-active.not-active {
    background: #fff !important;
    span {
      color: #998f8a !important;
    }
    svg path {
      fill: #998f8a !important;
    }

    &:hover {
      background-color: #feece1 !important;
      span {
        color: #974900 !important;
      }

      svg path {
        fill: #974900 !important;
      }
    }
  }
</style>
