<template>
  <div>
    <div
      v-if="origin == 'sidebar'"
      id="site_trigger"
    >
      <div
        v-if="variant === 'full'"
        class="site-selector"
        :class="{
          desktop: device === 'desktop',
          mobile: device !== 'desktop'
        }"
      >
        <div
          class="selector-wrapper"
          @click="open = !open"
        >
          <span>{{ currentSite.name }}</span>
          <div :class="['icon-full', open ? 'flipped' : '']">
            <ArrowRight />
          </div>
        </div>
        <transition name="navgroup">
          <div
            v-if="open"
            class="items"
          >
            <div
              class="nav-site-item"
              v-for="item in sites"
              :key="item.guid"
              @click="setSite(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </transition>
      </div>
      <div
        v-else
        class="site-selector desktop short"
        id="target-sites"
        tabindex="0"
      >
        <div class="selector-wrapper short">
          <span> {{ currentSite.name }} </span>
        </div>
        <Tooltip
          target="target-sites"
          :text="$t('Menu.Items.Sites')"
        />
        <Popover
          :items="sites"
          target="target-sites"
          :title="$t('Menu.Items.Sites')"
        />
      </div>
    </div>
    <div v-if="origin == 'navbar' && device == 'tablet'">
      <div
        class="site-selector navbar"
        id="site_trigger"
        tabindex="0"
      >
        <div
          class="selector-wrapper navbar"
          id="sites"
        >
          <span> {{ currentSite.name }} </span>
          <div class="arrow-down">
            <ArrowDown class="arrow-down-icon" />
          </div>
        </div>
      </div>
      <Popover
        :items="sites"
        target="site_trigger"
        :title="$t('Menu.Items.Sites')"
        placement="down"
      />
    </div>
  </div>
</template>
<script>
  import ArrowRight from '@core/assets/icons/arrow-right.svg';
  import ArrowDown from '@core/assets/icons/arrow-down.svg';
  import Tooltip from '../Tooltip.vue';
  import Popover from '../Popover.vue';
  import { BPopover } from 'bootstrap-vue';

  export default {
    name: 'SiteSelector',
    components: { ArrowRight, ArrowDown, Tooltip, Popover, BPopover },
    props: {
      origin: {
        type: String,
        default: 'sidebar'
      },
      variant: {
        type: String,
        default: 'full'
      },
      device: {
        type: String,
        default: 'desktop'
      },
      user: {
        type: Function,
        default: () => {
          return { first_name: '', last_name: '', photo: '' };
        }
      }
    },
    data: () => ({
      open: false,
      sites: [],
      currentSite: { name: '' }
    }),
    methods: {
      setSite(site) {
        localStorage.setItem('currentSite', JSON.stringify(site));
        const domain = window.location.href.includes('indsiders.com');
        const userInfo = this.$cookies.get('userInfo');

        this.$cookies.set(
          'userInfo',
          { ...userInfo, currentSite: site },
          null,
          null,
          domain ? 'indsiders.com' : null
        );

        this.$router.go();
      }
    },
    mounted() {
      this.sites = JSON.parse(localStorage.getItem('sites')).map((item) => {
        return {
          title: item.name,
          click: () => this.setSite(item),
          ...item
        };
      });
      this.currentSite = JSON.parse(localStorage.getItem('currentSite'));
    },
    beforeDestroy() {
      this.open = false;
    }
  };
</script>
<style lang="scss" scoped>
  .site-selector {
    width: 100%;
    transition: all 0.6s;

    cursor: pointer;
    user-select: none;
    color: #4c4541;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #eee;
    background-color: #fff;

    &.mobile,
    &.desktop {
      position: fixed;
      bottom: 0;
    }

    &.desktop {
      width: 260px;

      &.short {
        width: 98px;
      }
    }

    &.navbar {
      border-top: 0;
      border-left: 1px solid #eee;
    }

    .selector-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      padding: 16px;

      &.short {
        justify-content: center;
      }

      &:hover {
        background-color: #ffede2;
        color: #974900;
        svg {
          fill: #974900;
        }
      }

      &:active {
        background-color: #ffdbc4;
        svg {
          fill: #974900;
        }
      }

      .arrow-down {
        height: 100%;
        width: 16px;
        display: flex;
        align-items: center;
        svg {
          fill: #998f8a;
        }
        .arrow-down-icon {
          width: 16px;
          height: 16px;
        }
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      padding-block: 12px;
      .nav-site-item {
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        color: #998f8a;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */

        &:hover {
          background-color: #ffede2;
          color: #974900;
          svg {
            fill: #974900;
          }
        }

        &:active {
          background-color: #ffdbc4;
        }
      }
    }

    .icon-full {
      width: 16px;
      height: 16px;

      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        fill: #4c4541;
      }
      &.flipped {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }

    span {
      overflow: hidden;
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      text-overflow: ellipsis;

      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
  }

  @media (max-width: 768px) {
    .site-selector {
      &.navbar {
        height: 60px;
      }
    }
  }
</style>
