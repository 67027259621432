<template>
  <div class="navbar-header d-xl-block d-none">
    <div :class="[skin === 'light' || skin === 'bordered' ? 'menu-light' : 'menu-dark']"></div>
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          :to="{ name: 'home', params: { prefix: prefix } }"
        >
          <span class="brand-logo">
            <b-img
              :src="appLogoImageText"
              alt="logo"
              style="max-width: 180px"
            />
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
  import { BLink, BImg } from 'bootstrap-vue';
  import { $themeConfig } from '@themeConfig';
  import useAppConfig from '@core/app-config/useAppConfig';

  export default {
    components: {
      BLink,
      BImg
    },
    data() {
      return {
        appLogoImageText: '',
        theme: ''
      };
    },
    created() {
      this.changeLogo();
    },
    beforeUpdate() {
      this.changeLogo();
    },
    methods: {
      changeLogo() {
        this.theme = localStorage.getItem('vuexy-skin')
          ? localStorage.getItem('vuexy-skin')
          : 'light';
        this.appLogoImageText =
          this.theme == 'light'
            ? require('@/assets/images/logo/logo_texto_preto.png')
            : require('@/assets/images/logo/logo_texto_branco.png');
      }
    },
    setup() {
      const { appLogoImageText, theme } = $themeConfig.app;
      const { skin } = useAppConfig();
      return {
        prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
        appLogoImageText,
        theme,
        skin
      };
    }
  };
</script>

<style></style>
