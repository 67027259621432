<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderGroup(item)"
    class="dropdown nav-item"
    :class="{
      'sidebar-group-active active open': isActive,
      show: isOpen
    }"
    @mouseenter="() => updateGroupOpen(true)"
    @mouseleave="() => updateGroupOpen(false)"
  >
    <b-link class="nav-link dropdown-toggle d-flex align-items-center">
      <IconIntegration v-if="item.header == 'Integration'" />
      <IconSettings v-else-if="item.header == 'System Setting'" />
      <IconDashboard v-else-if="item.header == 'Dashboards'" />
      <span>{{ t(item.header) }}</span>
      <IconArrowDown class="icon-arrow" />
    </b-link>
    <div class="dropdown-menu">
      <ul style="list-style: none; padding: 0">
        <component
          :is="resolveHorizontalNavMenuItemComponent(child)"
          v-for="child in item.children.filter((item) => item.title != 'secondaryPage')"
          :key="child.title"
          :item="child"
        />
      </ul>
    </div>
  </li>
</template>

<script>
  import { BLink } from 'bootstrap-vue';
  import { resolveHorizontalNavMenuItemComponent } from '@core/layouts/utils';
  import { useUtils as useAclUtils } from '@core/libs/acl';
  import { useUtils as useI18nUtils } from '@core/libs/i18n';
  import useHorizontalNavMenuHeaderGroup from './useHorizontalNavMenuHeaderGroup';
  import mixinHorizontalNavMenuHeaderGroup from './mixinHorizontalNavMenuHeaderGroup';

  import HorizontalNavMenuGroup from '../horizontal-nav-menu-group/HorizontalNavMenuGroup.vue';
  import HorizontalNavMenuLink from '../horizontal-nav-menu-link/HorizontalNavMenuLink.vue';
  import IconIntegration from '@/assets/images/pages/integration-page-icon.svg';
  import IconSettings from '@/assets/images/pages/settings-page-icon.svg';
  import IconDashboard from '@/assets/images/pages/dashboard-page-icon.svg';
  import IconArrowDown from '@/assets/images/pages/arrow-down-icon.svg';

  export default {
    components: {
      BLink,
      HorizontalNavMenuGroup,
      HorizontalNavMenuLink,
      IconIntegration,
      IconSettings,
      IconDashboard,
      IconArrowDown
    },
    mixins: [mixinHorizontalNavMenuHeaderGroup],
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const { isActive, updateIsActive, isOpen, updateGroupOpen } = useHorizontalNavMenuHeaderGroup(
        props.item
      );

      const { t } = useI18nUtils();
      const { canViewHorizontalNavMenuHeaderGroup } = useAclUtils();

      return {
        isOpen,
        isActive,
        updateGroupOpen,
        updateIsActive,
        resolveHorizontalNavMenuItemComponent,

        // ACL
        canViewHorizontalNavMenuHeaderGroup,

        // i18n
        t
      };
    }
  };
</script>
<style lang="scss">
  .nav-link {
    .icon-arrow {
      margin-left: 8px;
      margin-right: 0 !important;
      margin-top: 7px;
    }
  }
</style>
